<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="interestAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row class="mb-3">
              <b-col class="col-12 col-sm-7 col-md-7 col-lg-8 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-5 col-md-5 col-lg-4 mb-3">
                <select
                  v-model="whereFilter.interest_cat_name"
                  type="text"
                  class="form-control"
                  id="validation_degree"
                  @change=setInterestFilter()
                  >
                  <option v-for="(IntType, index) of this.IntType" :value=IntType.interest_cat_name :key="index+'IntType'">{{ IntType.interest_cat_name }}</option>
                </select>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="interestObjList && interestObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="interestObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(interest_name)="data">
                    {{data.item.interest_name}}
                      <br>
                    <button class="btn actionIcon mr-1 mb-1" size="sm" @click="interestEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0 font-size-20"></i>
                    </button>
                    <button class="actionIcon btn " @click="showInterestDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0 font-size-20"></i>
                    </button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1 mb-3">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelInterestAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <InterestAdd :propOpenedInModal="true" @emitCloseInterestAddModal="closeInterestAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="interestAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeInterestAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelInterestEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <InterestEdit :propOpenedInModal="true" :propInterestObj="interestEditObj" @emitCloseInterestEditModal="closeInterestEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="interestEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeInterestEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelInterestDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelInterestDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="interestDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { Interests } from "../../../FackApi/api/interest.js"
import InterestAdd from "./InterestAdd"
import InterestEdit from "./InterestEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { InterestCategorys } from "../../../FackApi/api/interestCategory.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "InterestList",
  components: {
    InterestAdd,
    InterestEdit
  },
  data () {
    return {
      apiName: "interest_list",
      cvCardTitle: "Interests",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Interest",
      cvAddModalHeader: "Add Interest",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Interest List Response",
      showModelInterestAdd: false,
      showModelInterestEdit: false,
      showModelInterestDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Interest", key: "interest_name", class: "text-left", sortable: true },
        { label: "Interest Category", key: "interest_cat_name", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true }
      ],
      interestObjList: null,
      interestEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      IntType: [],
      whereFilter: {
        interest_cat_name: "Subject Area"
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    Intrests () {
      return this.$store.getters["Intrests/selectedIntrestState"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getFiltersFromCache()
    await this.interestCategoryList()
  },
  methods: {
    /**
     * get data from store cache by this method
     */
    async getFiltersFromCache () {
      await this.getIntrestFilter()
      await this.interestList()
    },
    /**
     * set filter by planamount
    */
    async setInterestFilter () {
      await this.interestList()
      await this.$store.commit("Intrests/addIntrest", this.whereFilter.interest_cat_name)
    },
    /**
     * Get Subscription Plan Amount
     */
    async getIntrestFilter () {
      let storeData = await this.Intrests
      if (storeData) {
        this.whereFilter.interest_cat_name = storeData
      }
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * interestList
     */
    async interestList () {
      try {
        let interestListResp = await Interests.interestList(this, this.whereFilter)
        if (interestListResp.resp_status) {
          this.interestObjList = interestListResp.resp_data.data
          this.totalRows = interestListResp.resp_data.count
        }
        else {
          this.toastMsg = interestListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryList
     */
    async interestCategoryList () {
      try {
        let interestCategoryListResp = await InterestCategorys.interestCategoryList(this)
        if (interestCategoryListResp.resp_status) {
          this.interestCategoryObjList = interestCategoryListResp.resp_data.data
          this.IntType = this.interestCategoryObjList
          this.totalRows = interestCategoryListResp.resp_data.count
        }
        else {
          this.toastMsg = interestCategoryListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryList() and Exception:", err.message)
      }
    },
    /**
     * interestAdd
     */
    interestAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/interest_add")
        }
        else {
          this.showModelInterestAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestAdd() and Exception:", err.message)
      }
    },
    /**
     * interestEdit
     */
    interestEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/interest_edit/" + this.interestEditObj.interest_id)
        }
        else {
          this.interestEditObj = item
          this.showModelInterestEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestEdit() and Exception:", err.message)
      }
    },
    /**
     * showInterestDeleteDialog
     */
    showInterestDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelInterestDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showInterestDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * interestDelete
     */
    async interestDelete () {
      try {
        let interestDelResp = await Interests.interestDelete(this, this.delObj.interest_id)
        await ApiResponse.responseMessageDisplay(this, interestDelResp)
        if (interestDelResp && !interestDelResp) {
          this.showModelInterestDelete = false
          return false
        }
        let index = this.interestObjList.indexOf(this.delObj)
        this.interestObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelInterestDelete = false
      }
      catch (err) {
        console.error("Exception occurred at interestDelete() and Exception:", err.message)
      }
    },
    /**
     * closeInterestAddModal
     */
    closeInterestAddModal (interestAddData) {
      try {
        if (interestAddData) {
          if (this.interestObjList && this.interestObjList.length >= 1) {
            let interestObjLength = this.interestObjList.length
            let lastId = this.interestObjList[interestObjLength - 1]["id"]
            interestAddData.id = lastId + 1
          }
          else {
            this.interestObjList = []
            interestAddData.id = 11111
          }
          // interestAddData.interest_id = `INTEREST${interestAddData.id}`
          interestAddData.created_on = moment()
          interestAddData.interest_cat_name = (interestAddData.interest_category && interestAddData.interest_category.interest_cat_name) ? interestAddData.interest_category.interest_cat_name : null
          this.interestObjList.unshift(interestAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelInterestAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeInterestAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeInterestEditModal
     */
    closeInterestEditModal () {
      try {
        this.showModelInterestEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeInterestEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
